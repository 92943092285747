import Navbar from '../../../components/navbar/navbar';
import ScrollToTop from '../../../components/scrollToTop/scrollToTop';
import './acas-protocol.css';
import navBarEn from "../../../translations/en/navbar.json";
import navBarEs from "../../../translations/es/navbar.json";
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

function AcasProtocol() {
  const [tAlerts] = useTranslation("alerts");
  const location = useLocation();
  const navigate = useNavigate();
  const {quill, quillRef} = useQuill({
    readOnly: true, 
    modules: {toolbar: false}
  });

  sessionStorage.setItem("routeEs", `/${navBarEs["category-services"]["alert"]}/${navBarEs["alerts-section"]['acas']}`);
  sessionStorage.setItem("routeEn", `/${navBarEn["category-services"]["alert"]}/${navBarEn["alerts-section"]['acas']}`);

  useEffect(() => {
    let lng = localStorage.getItem("lng") || 'es';
    let pathEs = sessionStorage.getItem('routeEs');
    let pathUs = sessionStorage.getItem('routeEn');
    
    if(lng === 'es' && pathEs !== location.pathname) {
      navigate(pathEs);
      window.location.reload();
    } else if (lng === 'us' && pathUs !== location.pathname){
      navigate(pathUs);
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if(quill) {
      const parsedContent = JSON.parse(tAlerts("acas.info1"));
      quill.setContents(parsedContent);
    }
  }, [quill]);

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <div className="container">
        <div className="border-top py-5">
          <p className="px-3 px-md-0 mt-3 mt-md-5 title-alert">
            {tAlerts("acas.text1")}
          </p>
          <p className="px-3 px-md-0 date-alert">{tAlerts("acas.text2")}</p>

          <div className="my-5 p-3 notice-conteiner">
            <div className='text-gray quill-content' ref={quillRef}></div>
          </div>

          <div className="d-flex justify-content-center justify-content-md-start">
            <Link className="btn-return" to="../">
              {tAlerts("btn-return.return")}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default AcasProtocol;