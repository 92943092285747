import { render } from '@testing-library/react';
import './main.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { FaChevronLeft } from "react-icons/fa";
import RenderMap from '../map/render-map';

function DestinationAndCoverage() {
  let data = {};
  const [typeDestinationView, setTypeDestinationView] = useState("");
  const [showStations, setShowStations] = useState(false);
  const [nationalStations, setNationalStations] = useState([]);
  const [internationalStations, setInternationalStations] = useState({});
  const [nationalDetails, setNationalDetails] = useState({});

  useEffect(() => {
    getNationalStations();
    getInternationalStations();
  }, []);

  useEffect(() => {
    setShowStations(data.showStations);
  }, [data])

  const getNationalStations = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=es`, {
        headers: {
          authorization: process.env.REACT_APP_PUBLIC_KEY,
        }
      })
      setNationalStations(response.data.stations);
    } catch (error) {
      console.error(error);
    }
  }

  const getInternationalStations = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/international-stations?lng=es`, {
        headers: {
          authorization: process.env.REACT_APP_PUBLIC_KEY,
        }
      });

      const regionGroup = response.data.stations.reduce((acc, item) => {
        let region = item.region;
        if(item.region === "Canada" || item.region.startsWith("USA")) {
          region = 'northAmerica'
        } else if(
          item.region === 'Caribe' ||
          item.region === 'Centroamerica' ||
          item.region === 'Sudamerica'
        ) {
          region = 'southAmerica'
        } else if(item.region === 'Europa') {
          region = 'europe';
        } else if(item.region === 'Asia') {
          region = 'asia';
        }

        if(!acc[region]) {
          acc[region] = {};
        }

        const country = item.country;

        if (!acc[region][country]) {
          acc[region][country] = [];
        }

        acc[region][country].push(item);

        return acc;
      }, {});
      setInternationalStations(regionGroup);
    } catch (error) {
      console.error(error);
    }
  }

  const handleMouseEnter = (destination) => {
    let countries = [];
    if(destination === 'national') {
      countries = document.querySelectorAll('.National');
    } else if(destination === 'international') {
      countries = document.querySelectorAll('.International');
    } else if(destination === 'america') {
      countries = document.querySelectorAll('.America');
    } else if(destination === 'asia') {
      countries = document.querySelectorAll('.Asia');
    } else if(destination === 'europe') {
      countries = document.querySelectorAll('.Europe')
    } else if(destination === 'northAmerica') {
      countries = document.querySelectorAll('.NorthAmerica');
    } else if(destination === 'southAmerica') {
      countries = document.querySelectorAll('.SouthAmerica');
    }

    countries.forEach((country) => {
      country.classList.add('active-color');
    });
  }

  const handleMouseLeave = () => {
    const countries = document.querySelectorAll('.active-color');
    countries.forEach((country) => {
      country.classList.remove('active-color');
    });
  }

  const changeView = (view) => {
    if(view === "" && Object.keys(nationalDetails).length) {
      setNationalDetails({});
      return;
    }
    handleMouseLeave();
    handleMouseEnter(view);
    setTypeDestinationView(view);
  }

  const stationDetail = (station) => {
    setNationalDetails(station);
  }

  const renderDestination = (data) => (
    <>
      {data.btnReturn && (
        <div 
          className={`btn-return`}
          onClick={() => changeView('')}
        >
          <FaChevronLeft />
        </div>
      )}

      <div className='dest-covera-section'>
        <h4>{data.title}</h4>

        <div className='buttons'>

          {data.btns.map((btn, index) => (
            <button 
              key={index}
              className={`${btn.static ? 'static' : ''}`}
              onMouseEnter={() => !btn.static && handleMouseEnter(btn.mouseEnter)}
              onMouseLeave={() => !btn.static && handleMouseLeave()}
              onClick={() => !btn.static && changeView(btn.mouseEnter)}
            >
              {btn.title}
            </button>
          ))}
        </div>
        
      </div>
    </>
  )

  const renderDestinationView = () => {
    switch(typeDestinationView) {
      case 'national':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": true,
          "btns": [
            {"title": 'Nacionales', "static": true, "mouseEnter": ""}
          ]
        }
        return renderDestination(data);
      case 'international':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": false,
          "btns": [
            {"title": 'América', "static": false, "mouseEnter": "america"},
            {"title": 'Asia', "static": false, "mouseEnter": "asia"},
            {"title": 'Europa', "static": false, "mouseEnter": "europe"}
          ]
        }
        return renderDestination(data);
      case 'america':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": false,
          "btns": [
            {"title": 'Norteamérica', "static": false, "mouseEnter": "northAmerica"},
            {"title": 'Centroamérica, Sudamérica y Caribe', "static": false, "mouseEnter": "southAmerica"},
          ]
        }
        return renderDestination(data);
      case 'northAmerica':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": true,
          "btns": [
            {"title": 'Norteamérica', "static": true, "mouseEnter": ""}
          ]
        }
        return renderDestination(data);
      case 'southAmerica':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": true,
          "btns": [
            {"title": 'Southamérica', "static": true, "mouseEnter": ""}
          ]
        }
        return renderDestination(data);
      case 'asia':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": true,
          "btns": [
            {"title": 'Asia', "static": true, "mouseEnter": ""}
          ]
        }
        return renderDestination(data);
      case 'europe':
        data = {
          "btnReturn": true,
          "title": "Destinos y coberturas",
          "showStations": true,
          "btns": [
            {"title": 'Europa', "static": true, "mouseEnter": ""}
          ]
        }
        return renderDestination(data);
      default:
        handleMouseLeave();
        data = {
          "btnReturn": false,
          "title": "Destinos y coberturas",
          "showStations": false,
          "btns": [
            {"title": 'Nacionales', "static": false, "mouseEnter": "national"},
            {"title": 'Internacionales', "static": false, "mouseEnter": "international"}
          ]
        }
        return renderDestination(data);
    }
  }

  const renderStations = () => {
    if(typeDestinationView === 'national') {
      return (
        Object.keys(nationalDetails).length ? (
          <div className='national-details'>
            <h4>{nationalDetails.name} ({nationalDetails.code_text})</h4>
            <p>{nationalDetails.address}</p>
            <h4>Teléfono(s):</h4>
            <p>{nationalDetails.phone}</p>
          </div>
        ) : (
          <div className='stations-container'>
            <ul>
              {nationalStations.map((station, index) => (
                <li key={index} onClick={() => stationDetail(station)}>
                  <p>{station.name} ({station.code_text})</p>
                </li>
              ))}
            </ul>
          </div>
        )
      )
    } else {
      return (
        <div className='international-stations-container'>
          {Object.entries(internationalStations)
            .filter(([region]) => region === typeDestinationView)
            .flatMap(([_, countries]) => 
              Object.entries(countries).map(([country, stations]) => (
                <div key={country} className='item-section'>
                  <p className='country-text'>
                    {country}
                  </p>
                  <ul>
                    {stations.map((station, index) => (
                      station.maps ? (
                        <li key={index}>
                          <a href={station.maps} target='_blank'>{station.city}</a>
                        </li>
                      ) : (
                        <li key={index}>
                          {station.city}
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              ))
            )
          }
        </div>
      )
    }
  }

  

  return(
    <>
      <div className='coverage-container'>
        <div className='gray-section'>
          
          {renderDestinationView()}

        </div>

        <div className='white-section'>
          <RenderMap />
          <div className={`show-stations ${showStations ? 'show' : ''}`}>
            {renderStations()}
          </div>
        </div>
      </div>
    </>
  )

}

export default DestinationAndCoverage;