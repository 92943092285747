import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import iconClose from '../../../assets/imgs/landing-page/icon_close.svg';
import './services.css';

const ContentDisplay = ({ content }) => {
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: {toolbar: false}
  });

  useEffect(() => {
    if (quill) {
      // Limpia el editor y carga el contenido desde JSON.
      quill.setContents(JSON.parse(content));
    }
  }, [quill, content]);

  return (
    <div>
      <div ref={quillRef} style={{ border: 'none' }} />
    </div>
  );
};

const InternationalModal = ({ isOpen, onClose, cardData, apiData }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close-button" onClick={onClose}>
            <img src={iconClose} alt="Cerrar" className="close-icon" />
          </button>
          <div className='modal-scrollable-content'>
            <img src={cardData.banner} alt={cardData.title} className="modal-image" />
            <div className="modal-text-content">
              <h2>INTERNACIONALES / <span>{apiData.view.toUpperCase()}</span></h2>
              <ContentDisplay content={apiData.text_es["01-descripción"].content} />
              {/* <p>Express es nuestra solución para envíos urgentes. Con Express, garantizamos que sus envíos urgentes despeguen y se encuentren volando rápidamente a más de 900 destinos en 175 países.</p>
              <h5>CARACTERÍSTICAS Y BENEFICIOS</h5>
              <ul>
                <li>Máxima prioridad de embarque.</li>
                <li>Entrega anticipada después de la llegada.</li>
                <li>Asignación de vuelo al momento de la documentación.</li>
                <li>Aplica sólo en mercancía general.</li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default InternationalModal;