import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import iconClose from '../../../assets/imgs/landing-page/icon_close.svg';
import './services.css';

const ContentDisplay = ({ content }) => {
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: {toolbar: false}
  });

  useEffect(() => {
    if (quill && content) {
      // Parsea el contenido JSON
      let parsedContent;
      try {
        parsedContent = JSON.parse(content);

        // Modificar el color a blanco
        parsedContent.ops.forEach(op => {
          if (op.attributes && op.attributes.color) {
            op.attributes.color = "#FFFFFF"; // Cambia el color a blanco
          }
          if (op.insert && typeof op.insert === "string") {
            op.insert = op.insert.replace(/\\n/g, " "); // Reemplaza saltos de línea
          }
        });
        
        // Guardar el contenido modificado en el editor Quill
        quill.setContents(parsedContent);
      } catch (error) {
        console.error("Error parsing content:", error);
      }
    }
  }, [quill, content]);

  return (
    <div>
      <div ref={quillRef} style={{ border: 'none' }} />
    </div>
  );
};

const NationalModal = ({ isOpen, onClose, cardData, jsonData, apiData }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>
          <img src={iconClose} alt="Cerrar" className="close-icon" />
        </button>
        <div className='modal-scrollable-content'>
          <img src={cardData.image} alt={cardData.title} className="modal-image" />
          <div className="modal-text-content">
            <h2>NACIONALES / <span>{apiData.view.toUpperCase()}</span></h2>
            <ContentDisplay content={apiData.text_es["01-descripción"].content} />
            {/* <p>Confianza y seguridad sin igual cuando para su embarque el tiempo es apremiante. Manténgase tranquilo sabiendo que su carga será entregada de la manera más rápida y segura. Desde la reserva hasta su entrega, su embarque GOLD es manejado con sumo cuidado, con selección de vuelo, vuelo de respaldo, alta prioridad en abordaje y las conexiones más rápidas.</p>
            <h5>CARACTERÍSTICAS Y BENEFICIOS</h5>
            <ul>
              <li>Para embarques que son transportados en un vuelo específico.</li>
              <li>Prioridad en el abordaje y pronta entrega en el aeropuerto de destino.</li>
              <li>Asignación de vuelo al momento de la documentación.</li>
              <li>Aplica sólo en mercancía general.</li>
            </ul> */}

            {/*Accordions*/}
            <Accordion className="custom-accordion" alwaysOpen>

              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {apiData.text_es["02-REMESA"] ? "Remesa" : "Restricciones y regulaciones"}
                </Accordion.Header>
                <Accordion.Body>
                  <ContentDisplay content={
                    apiData.text_es["02-REMESA"]?.content ||
                    apiData.text_es["02-Restricciones y Regulaciones"]?.content ||
                    jsonData["accordion-1"].content
                    } 
                  />
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {apiData.text_es["03-PRENSA"] ? "Prensa" : "Documentación, Aceptación y Recolección"}
                </Accordion.Header>
                <Accordion.Body>
                  <ContentDisplay content={
                    apiData.text_es["03-PRENSA"]?.content ||
                    apiData.text_es["03-Documentación, Aceptación y Recolección"].content
                    } />
                </Accordion.Body>
              </Accordion.Item>

              {(apiData.text_es["04-PIEZA"] || apiData.text_es["04-Embalaje y Manejo Especial"] || apiData.text_es["04-Manejo Especial"] || jsonData["accordion-3"]) && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {apiData.text_es["04-PIEZA"] ? "Pieza" :
                  apiData.text_es["04-Embalaje y Manejo Especial"] ? "Embalaje y Manejo Especial" : 
                  "Manejo Especial"}
                </Accordion.Header>
                <Accordion.Body>
                  <ContentDisplay content={
                    apiData.text_es["04-PIEZA"]?.content ||
                    apiData.text_es["04-Embalaje y Manejo Especial"]?.content || 
                    apiData.text_es["04-Manejo Especial"]?.content || 
                    jsonData["accordion-3"].content
                    } 
                  />
                </Accordion.Body>
              </Accordion.Item>
              )}

              {/* Cuarto Accordion: solo si existe "05-MUESTRAS EXENTAS" */}
              {apiData.text_es["05-MUESTRAS EXENTAS"] && (
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Muestras Exentas</Accordion.Header>
                  <Accordion.Body>
                    <ContentDisplay content={apiData.text_es["05-MUESTRAS EXENTAS"].content} />
                  </Accordion.Body>
                </Accordion.Item>
              )}

            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NationalModal;