import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import './services.css';
// Iconos
import deliveryIcon from '../../../assets/imgs/landing-page/entrega_icon.svg';
import packingIcon from '../../../assets/imgs/landing-page/empaque_icon.svg';
import storageIcon from '../../../assets/imgs/landing-page/almacenaje_icon.svg';
import valuationIcon from '../../../assets/imgs/landing-page/valuacion_icon.svg';
// JSON
import additionalData from '../../../translations/es/products/additional-services/additional-services.json';

const ContentDisplay = ({ content }) => {
  const { quill, quillRef } = useQuill({
    readOnly: true,
    modules: {toolbar: false}
  });

  useEffect(() => {
    if (quill) {
      // Limpia el editor y carga el contenido desde JSON.
      quill.setContents(JSON.parse(content));
    }
  }, [quill, content]);

  return (
    <div>
      <div ref={quillRef} style={{ border: 'none' }} />
    </div>
  );
};

function Additional() {
  console.log(additionalData.header.title);
  const cards = [
    { id: 1, title: additionalData["home-delivery"].title, icon: deliveryIcon, description: additionalData["home-delivery"].description },
    { id: 2, title: additionalData.packaging.title, icon: packingIcon, description: additionalData.packaging.description },
    { id: 3, title: additionalData.storage.title, icon: storageIcon, description: additionalData.storage.description },
    { id: 4, title: additionalData.valuation.title, icon: valuationIcon, description: additionalData.valuation.description },
  ];
  return (
    <div className='service-container'>
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        loop={false} // Desactivar el bucle para evitar que vuelva al inicio
        resistanceRatio={0} // Evitar el efecto de resorte
      >
        {cards.map((card) => (
          <SwiperSlide key={card.id} style={{ width: "400px" }}>
            <div className="card-additional">
              <div className="card-additional-content">
                <img src={card.icon} alt={`${card.title} icon`} className="card-icon" /> {/* Ícono como imagen */}
                <h4 className="card-additional-title mt-4">{card.title}</h4>
                <p className="description-additional">{card.description}</p> {/* Descripción que se muestra en hover */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Additional;