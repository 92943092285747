import { React } from "react";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import Navbar from "../../components/navbar/navbar";
import TitleSection from "../../components/title-section/title-section";
import NoticesAlerts from "../../components/notices-alerts/notices-alerts";
import Footer from "../../components/footer/Footer";
import "./alerts.css";
import { useTranslation } from "react-i18next";
import navBarEn from "../../translations/en/navbar.json";
import navBarEs from "../../translations/es/navbar.json";
import Helmet from "react-helmet";

function Alerts() {
  const [tAlerts] = useTranslation("alerts");
  const [tNabvar] = useTranslation("navbar");
  const [tSMetaData] = useTranslation("metaData");
  sessionStorage.setItem("routeEs", `/${navBarEs["category-services"].alert}`);

  sessionStorage.setItem("routeEn", `/${navBarEn["category-services"].alert}`);

  return (
    <>
      <Helmet>
        <title>{tSMetaData("alertas.title")}</title>
        <meta name="description" content={tSMetaData("alertas.description")} />
      </Helmet>
      <ScrollToTop />
      <Navbar />
      <TitleSection title={tAlerts("title.title-page")} />
      <div className="container-fluid py-5 bg-alert">
        <div className="container">
          <p className="alert-info">
            {tAlerts("alerts-info.text1")}
            <span className="fw-bold font-semibold">
              {tAlerts("alerts-info.text2")}
            </span>
            {tAlerts("alerts-info.text3")}
          </p>
        </div>
      </div>
      <div className="pb-5">
        <NoticesAlerts
          title={tAlerts("acas.text1")}
          date={tAlerts("acas.text2")}
          info={tAlerts("acas.text3")}
          to={`${tNabvar("alerts-section.acas")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="acasProtocol"
        />
        <NoticesAlerts
          title={tAlerts("notice1.text1")}
          date={tAlerts("notice1.text2")}
          info={tAlerts("notice1.text3")}
          to={`${tNabvar("alerts-section.ics2-regulacion")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeRegulation"
        />
        <NoticesAlerts
          title={tAlerts("notice2.text1")}
          date={tAlerts("notice2.text2")}
          info={tAlerts("notice2.text3")}
          to={`${tNabvar("alerts-section.pharma-services")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeServicioPharma"
        />
        <NoticesAlerts
          title={tAlerts("notice3.text1")}
          date={tAlerts("notice3.text2")}
          info={tAlerts("notice3.text3")}
          to={`${tNabvar("alerts-section.againts-covid")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeLuchaContra"
        />
        <NoticesAlerts
          title={tAlerts("notice4.text1")}
          date={tAlerts("notice4.text2")}
          info={tAlerts("notice4.text3")}
          to={`${tNabvar("alerts-section.aeromexico-proud")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeAMProud"
        />
        <NoticesAlerts
          title={tAlerts("notice5.text1")}
          date={tAlerts("notice5.text2")}
          info={tAlerts("notice5.text3")}
          to={`${tNabvar("alerts-section.no-show-policy")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeNoShow"
        />
        <NoticesAlerts
          title={tAlerts("notice6.text1")}
          date={tAlerts("notice6.text2")}
          info={tAlerts("notice6.text3")}
          to={`${tNabvar("alerts-section.aeromexico-obtains-iata")}`}
          btn={tAlerts("btn-more.learn-more")}
          id="noticeIATA"
        />
      </div>
      <Footer />
    </>
  );
}

export default Alerts;
