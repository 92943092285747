import React, { useState, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Collapse } from "react-collapse";
import { AiFillLinkedin, AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import logo from "../../assets/imgs/navbar/logo_AMC.svg";
import logoSky from "./images/logo-sky.svg";
import rastrea from "./images/rastrea.svg";
import cotiza from "./images/cotiza.svg";
import restricciones from "./images/restricciones.svg";
import nosotros from "./images/nosotros.svg";
import alertas from "./images/alertas.svg";
import facturacion from "./images/facturacion.svg";
import certificados from "./images/certificados.svg";
import "./Footer.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../scrollToTop/scrollToTop";
import ScrollToDynamic from "../scrollToDynamic/scroolToDynamic";

function Footer(props) {
  const [tNabvar] = useTranslation("navbar");
  const [tFooter] = useTranslation("footer");
  const [isCheckboxCollapseOpen, setIsCheckboxCollapseOpen] = useState(true);
  const [isCheckboxCollapseOpen2, setIsCheckboxCollapseOpen2] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onChange = useCallback(
    ({ target: { checked } }) => setIsCheckboxCollapseOpen(checked),
    [setIsCheckboxCollapseOpen]
  );

  const onChange2 = useCallback(
    ({ target: { checked } }) => setIsCheckboxCollapseOpen2(checked),
    [setIsCheckboxCollapseOpen2]
  );

  const handleClickPrivacy = () => {
    let lastRouteUs = "";
    let lastRouteEs = "";
    if (
      !sessionStorage.getItem("routeEn") ||
      !sessionStorage.getItem("routeEs") ||
      !localStorage.getItem("lng")
    ) {
      lastRouteEs = "/";
      lastRouteUs = "/";
    } else {
      lastRouteUs = `${sessionStorage.getItem("routeEn")}`;
      lastRouteEs = `${sessionStorage.getItem("routeEs")}`;
    }
    navigate(`/${tNabvar("category-services.privacy")}`, {
      state: { us: lastRouteUs, es: lastRouteEs },
    });
  };

  return (
    <React.StrictMode>
      <footer className="footer ">
        <div className="container">
          <div className="row px-md-0 px-4">
            <div className="col-lg-4 col-md-12">
              <div className="row">
                <div className="col-lg-12 col-md-4 mb-md-5">
                  <div className="footer__contact__phone">
                    <h4>
                      {tFooter("column1.text-1")}
                      <br />
                      {tFooter("column1.text-2")}
                    </h4>
                    <p className="footer__contact__phone_p">
                      {tFooter("column1.text-3")}
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4">
                  <div className="row">
                    <div className="col-lg-6 mt-4 mt-md-0">
                      <Link
                        id="btnContactFooter"
                        className="btn btn-primary ctm-button footer__contact__buttons_a"
                        to={`/${tNabvar("category-services.contact")}`}
                      >
                        {tFooter("column1.text-4")}
                      </Link>
                      {/* <a href="/contact" className="btn btn-primary ctm-button footer__contact__buttons_a" role="button">
												{ tFooter("column1.text-4") }
											</a>	 */}
                    </div>
                    <div className="col-lg-6 text-center">
                      <div className="d-flex align-items-center justify-content-center vertical-align-center">
                        <Link
                          id="btnPhoneTrack"
                          className="no-a text-white d-none d-md-flex mt-2 phoneText"
                          to={`/${tNabvar("category-services.contact")}`}
                        >
                          (55) 5133 0237
                        </Link>
                        <a
                          href="tel:5551330237"
                          target="_blank"
                          rel="noreferrer"
                          className="no-a d-block d-md-none mt-4 mb-4 phoneText"
                        >
                          (55) 5133 0237
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-4 mt-lg-4 mb-5">
                  <div className="row d-flex align-items-center">
                    <div className="col-6 col-lg-5 p-4 p-md-2 p-lg-3">
                      <Link className="" to="/" onClick={handleClick} id='btnFooterHome'>
                        <img src={logo} alt="AMC Logo" />
                      </Link>
                      {/* <a href="/">
												<img src={ logo } alt='AMC Logo' /> 
											</a> */}
                    </div>
                    <div className="col-6 col-lg-5 p-4 p-md-2 p-lg-3 text-white">
                      <a
                        rel="noreferrer"
                        href={tFooter("column1.skyteamLink")}
                        target="_blank"
                        className="text-white"
                        name="skyTeamButtonFooter"
                        id="skyTeamButtonFooter"
                      >
                        <img src={logoSky} alt="Skyteam Cargo Logo" />
                      </a>
                    </div>

                    <div className="mt-3 d-block d-md-none">
                      <font size="2.5">{tFooter("column1.text-5")}:</font>{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/amxcargo/"
                        className="no-a-footer"
                      >
                        <AiFillLinkedin className="fs-18 ms-3" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 middle-column mb-md-0 mb-5">
              <div className="footer__section__services">
                <label className="footer__section__services_h5 d-flex align-items-center">
                  {tFooter("column2.text-1")}
                  <input
                    className="input d-none"
                    type="checkbox"
                    checked={isCheckboxCollapseOpen}
                    onChange={onChange}
                  />

                  {isCheckboxCollapseOpen ? (
                    <AiFillCaretUp className="d-md-none d-flex text-end-normal fs-9" />
                  ) : (
                    <AiFillCaretDown className="d-md-none d-flex text-end-normal fs-9" />
                  )}
                </label>
                <Collapse isOpened={isCheckboxCollapseOpen}>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img className="servicios-icon" src={rastrea} alt="" />
                    <Link
                      id="linkTrackFooter"
                      className="ms-2 servicios-footer m-0"
                      to={`/${tNabvar("category-services.track")}`}
                    >
                      {tFooter("column2.text-2")}
                    </Link>
                    {/* <span className="ms-2">{ tFooter("column2.text-2") }</span> */}
                  </div>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img className="servicios-icon" src={cotiza} alt="" />
                    <Link
                      id="linkQuoteFooter"
                      className="ms-2 servicios-footer m-0"
                      to={`/${tNabvar("category-services.quote")}`}
                    >
                      {tFooter("column2.text-3")}
                    </Link>
                    {/* <span className="ms-2">{ tFooter("column2.text-3") }</span> */}
                  </div>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img
                      className="servicios-icon2 ms-md-1"
                      src={restricciones}
                      alt=""
                    />
                    <Link
                      id="linkRestrictionsFooter"
                      className="ms-2 servicios-footer m-0"
                      to={`/${tNabvar("category-services.shipments")}/${tNabvar("prepare-shipment-submenu.restricciones")}`}
                    >
                      {tFooter("column2.text-4")}
                    </Link>
                    {/* <span className="ms-2">{ tFooter("column2.text-4") }</span> */}
                  </div>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img src={nosotros} className="servicios-icon" alt="" />
                    <Link
                      id="linkAboutUsFooter"
                      className="ms-2 servicios-footer m-0"
                      to={`/${tNabvar("category-services.about-us")}/${tNabvar("category-services.aeromexico-cargo")}`}
                    >
                      {tFooter("column2.text-5")}
                    </Link>
                    {/* <span className="ms-2">{ tFooter("column2.text-5") }</span> */}
                  </div>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img src={alertas} className="servicios-icon" alt="" />
                    <Link
                      id="linkAlertsFooter"
                      className="ms-2 servicios-footer m-0"
                      to={`/${tNabvar("category-services.alert")}`}
                    >
                      {tFooter("column2.text-6")}
                    </Link>
                    {/* <span className="ms-2">{ tFooter("column2.text-6") }</span> */}
                  </div>
                  <div className="servicios-footer c-pointer d-flex align-items-center">
                    <img src={facturacion} className="servicios-icon" alt="" />
                    <Link
                      id="linkInvoicingFooter"
                      className="ms-2 servicios-footer m-0"
                      to="https://portalfacturacioncargo.aeromexico.com/Portal/Cargo/Account/Login"
                      target="blank"
                    >
                      {tFooter("column2.text-7")}
                    </Link>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 left-column">
              <div className="footer__information">
                <label className="footer__section__services_h5 d-flex align-items-center">
                  {tFooter("column3.text-1")}
                  <input
                    className="input d-none"
                    type="checkbox"
                    checked={isCheckboxCollapseOpen2}
                    onChange={onChange2}
                  />
                  {isCheckboxCollapseOpen2 ? (
                    <AiFillCaretUp className="d-md-none d-flex text-end-normal fs-9" />
                  ) : (
                    <AiFillCaretDown className="d-md-none d-flex text-end-normal fs-9" />
                  )}
                </label>
                <Collapse isOpened={isCheckboxCollapseOpen2}>
                  <div className="row">
                    <div className="col-12 col-md-6 mt-3">
                      <Link
                        id="linkContractsFooter"
                        className="mt-3 servicios-footer"
                        to={`/${tNabvar("category-services.contracts")}`}
                      >
                        {tFooter("column3.text-2")}
                      </Link>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <Link
                        id="linkComplianceFooter"
                        className="mt-3 servicios-footer"
                        to={`/${tNabvar("category-services.compliance")}`}
                      >
                        {tFooter("column3.text-3")}
                      </Link>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <div className="mt-3">
                        <Link
                          id="linkContactFooter"
                          className=" servicios-footer"
                          to={`/${tNabvar("category-services.contact")}`}
                        >
                          {tFooter("column3.text-4")}
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <div className="mt-3">
                        <a
                          id="linkPrivacyFooter"
                          className="mt-3 servicios-footer"
                          onClick={handleClickPrivacy}
                        >
                          {tFooter("column3.text-5")}
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <div className="mt-3">
                        <Link
                          id="linkEticsFooter"
                          className=" servicios-footer"
                          to={`/${tNabvar(
                            "category-services.compliance"
                          )}/#compliance_ethics`}
                        >
                          {tFooter("column3.text-6")}
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mt-3">
                      <div className="mt-3">
                        <Link
                          id="linkConductFooter"
                          className="mt-3 servicios-footer"
                          to={`/${tNabvar(
                            "category-services.compliance"
                          )}/#comp_conduct_info_conta`}
                        >
                          {tFooter("column3.text-7")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </Collapse>
                <div className="mt-5 d-none d-md-block">
                  <font size="2.5">{tFooter("column1.text-5")}:</font>{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/amxcargo/"
                    id="linkLinkedinFooter"
                    className="no-a-footer"
                  >
                    <AiFillLinkedin className="fs-18 ms-3" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-12 copy-footer">
              <div className="row">
                <div className="col-md-6">
                  <p className="footer__contact__phone_p d-none d-md-block">
                    ©Copyright {new Date().getFullYear()}{" "}
                    {tFooter("column4.text-1")}
                  </p>
                  <p className="footer__contact__phone_p d-block d-md-none text-center fs-12">
                    ©Copyright {new Date().getFullYear()}{" "}
                    {tFooter("column4.text-1")}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="footer__contact__phone_p text-end d-none d-md-block">
                    {tFooter("column4.text-2")}{" "}
                    <img
                      className="servicios-icon2"
                      src={certificados}
                      alt=""
                    />
                  </p>
                  <p className="footer__contact__phone_p d-block d-md-none text-center fs-12">
                    {tFooter("column4.text-2")}{" "}
                    <img
                      className="servicios-icon2"
                      src={certificados}
                      alt=""
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.StrictMode>
  );
}

export default Footer;