import { useEffect, useState } from 'react';
import './shipping-process.css';
import { FaCheck } from "react-icons/fa";

function ShippingProcess () {
  const [progressStatus, setProgressStatus] = useState(1);

  useEffect(() => {
    if(progressStatus >= 5) {
      setProgressStatus(1);
    }
    setTimeout(() => {
      setProgressStatus(progressStatus + 1);
    }, 5000);
  }, [progressStatus])

  return (
    <>
      <div className='shipping-prcs-title'>
        <h3>Proceso de envío</h3>
      </div>

      <div className='shipp-prcs-animation'>
        <div className='cube'>
          <div className='circle active'><FaCheck /></div>
          <div className={`line-right ${progressStatus > 1 ? 'active' : ''}`}></div>
        </div>

        <div className='cube'>
          <div className={`line-left ${progressStatus > 1 ? 'active' : ''}`}></div>
          <div className={`circle ${progressStatus > 1 ? 'active' : ''}`}><FaCheck /></div>
          <div className={`line-right ${progressStatus > 2 ? 'active' : ''}`}></div>
        </div>

        <div className='cube'>
          <div className={`line-left ${progressStatus > 2 ? 'active' : ''}`}></div>
          <div className={`circle ${progressStatus > 2 ? 'active' : ''}`}><FaCheck /></div>
          <div className={`line-right ${progressStatus > 3 ? 'active' : ''}`}></div>
        </div>

        <div className='cube'>
          <div className={`line-left ${progressStatus > 3 ? 'active' : ''}`}></div>
          <div className={`circle ${progressStatus > 3 ? 'active' : ''}`}><FaCheck /></div>
        </div>
      </div>

      <div className='status-text'>
        <p className={`${progressStatus === 1 ? 'active' : ''}`}>Acude a un módulo y asesórate con un ejecutivo</p>
        <p className={`${progressStatus === 2 ? 'active' : ''}`}>Valida tu carga</p>
        <p className={`${progressStatus === 3 ? 'active' : ''}`}>Contratación de servicio</p>
        <p className={`${progressStatus === 4 ? 'active' : ''}`}>Recoge en el destino</p>
      </div>
    </>
  )
}

export default ShippingProcess;